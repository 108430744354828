// import { Button, Container } from "react-bootstrap";
// import { motion } from "framer-motion";
// import AnimationTitles from "../components/functions/AnimationTitles";
// import { useNavigate } from "react-router-dom";

// function AboutUs() {
//   const navigate = useNavigate();

//   // Function to navigate to the desired page
//   const handleNavigate = () => {
//     navigate("/about-us"); // Change '/some-page' to your desired path
//   };

//   return (
//     <div className="about">
//       <Container className="d-flex justify-content-between flex-wrap flex-md-nowrap">
//         <motion.div
//           initial={{ x: -200 }}
//           whileInView={{ x: 0 }}
//           transition={{ duration: 0.8 }}
//         >
//           <AnimationTitles title="What is Renting-Platform?" className="title" />
//           <p className="gray-50 mb-5">
//             As new technologies like cryptocurrency develop, the real estate
//             sector is changing drastically. It is important to understand both
//             how these technologies and the traditional real estate market work.
//             Governments are unable to comprehend the rapid advancement of
//             technology and modify their legal frameworks to accommodate it fast
//             enough.
//           </p>
//           {/* Trigger navigation on button click */}
//           <Button variant="primary ms-0" onClick={handleNavigate}>
//             Read More
//           </Button>
//         </motion.div>

//         <motion.div
//           initial={{ x: 200 }}
//           whileInView={{ x: 0 }}
//           transition={{ duration: 0.8 }}
//           className="d-flex flex-column"
//         >
//           <div className="d-flex">
//             <div>
//               <img
//                 src={require("..//images/bohdan-d-fh6o-XkVQG8-unsplash.webp")}
//                 className="p-0 me-2 img"
//                 alt="img"
//               />
//             </div>
//             <div>
//               <img
//                 src={require("..//images/john-o-nolan-6f_ANCcbj3o-unsplash.webp")}
//                 className="p-0 img"
//                 alt="img"
//               />
//             </div>
//           </div>
//           <div className="d-flex">
//             <div>
//               <img
//                 src={require("..//images/julia-solonina-ci19YINguoc-unsplash.webp")}
//                 className="p-0 me-2 img"
//                 alt="img"
//               />
//             </div>
//             <div>
//               <img
//                 src={require("..//images/theater-amazonas-manaus.webp")}
//                 className="p-0 img"
//                 alt="img"
//               />
//             </div>
//           </div>
//         </motion.div>
//       </Container>
//     </div>
//   );
// }

// export default AboutUs;

import React from 'react'; // Required for functional components in React
import { Container, Button } from "react-bootstrap";
import { motion } from "framer-motion";
import AnimationTitles from "../components/functions/AnimationTitles";
import "bootstrap/dist/css/bootstrap.min.css"; // Make sure the styles are applied

function AboutUs() {
  return (
    <div className="about">
      <Container className="d-flex justify-content-between flex-wrap flex-md-nowrap">
        <motion.div
          initial={{ x: -200 }}
          whileInView={{ x: 0 }}
          transition={{ duration: 0.8 }}
        >
          <AnimationTitles title="What is Renting-Platform?" className="title" />
          <p className="gray-50 mb-5">
            As new technologies like cryptocurrency develop, the real estate
            sector is changing drastically. It is important to understand both
            how these technologies and the traditional real estate market work.
            Governments are unable to comprehend the rapid advancement of
            technology and modify their legal frameworks to accommodate it fast
            enough.
          </p>
          {/* Optionally, you could keep this button to navigate to another page */}
          <Button variant="primary ms-0">
            Read More
          </Button>
        </motion.div>

        <motion.div
          initial={{ x: 200 }}
          whileInView={{ x: 0 }}
          transition={{ duration: 0.8 }}
          className="d-flex flex-column"
        >
          {/* Your images */}
          <div className="d-flex">
            <div>
              <img
                src={require("..//images/bohdan-d-fh6o-XkVQG8-unsplash.webp")}
                className="p-0 me-2 img"
                alt="img"
              />
            </div>
            <div>
              <img
                src={require("..//images/john-o-nolan-6f_ANCcbj3o-unsplash.webp")}
                className="p-0 img"
                alt="img"
              />
            </div>
          </div>
          <div className="d-flex">
            <div>
              <img
                src={require("..//images/julia-solonina-ci19YINguoc-unsplash.webp")}
                className="p-0 me-2 img"
                alt="img"
              />
            </div>
            <div>
              <img
                src={require("..//images/theater-amazonas-manaus.webp")}
                className="p-0 img"
                alt="img"
              />
            </div>
          </div>
        </motion.div>
      </Container>
    </div>
  );
}

export default AboutUs;

