import { BrowserRouter, Routes, Route } from "react-router-dom";
import AboutUs from "./pages/AboutUs";
import NavBar from "./components/navbar/Navbar";
import Developers from "./pages/Developers";
import Footer from "./pages/Footer";
import Join from "./pages/Join";
import Loading from "./pages/Header";
import Partners from "./pages/Partners";
import Properties from "./pages/Properties";
import Subscribe from "./pages/Subscribe";
import Home from './pages/home';


function App() {
  return (
    <>
      <BrowserRouter>
        <NavBar />
        <Loading />
        
        <Routes>
          <Route path="/" element={<Partners />} />
          <Route path="/home" element={<Home />} />
       
          <Route path="/properties" element={<Properties />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/developers" element={<Developers />} />
          <Route path="/join" element={<Join />} />
          <Route path="/subscribe" element={<Subscribe />} />
          
        </Routes>
        
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
